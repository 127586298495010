export const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL;
export const NEXT_PUBLIC_API_DOCKER_URL =
  process.env.NEXT_PUBLIC_API_DOCKER_URL ||
  'http://microsite-graphql:8015/local/graphql';
export const NEXT_PUBLIC_MICROSITE_API_URL =
  process.env.NEXT_PUBLIC_MICROSITE_API_URL;
export const NEXT_PUBLIC_X_API_KEY_GRAPHQL =
  process.env.NEXT_PUBLIC_X_API_KEY_GRAPHQL;
export const NEXT_PUBLIC_ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT;
export const NEXT_PUBLIC_STARTED_FREE_URL =
  'https://www.beeliked.com/get-started-for-free';
