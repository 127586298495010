import { NEXT_PUBLIC_ENVIRONMENT } from '.';

type Domains = {
  local: string[];
  development: string[];
  uat: string[];
  production: string[];
};

enum Env {
  local = 'local',
  development = 'development',
  uat = 'uat',
  production = 'production',
}

const domains: Domains = {
  local: ['pollen8.local:3015', 'beeliked.local.promo:3015'],
  development: ['microsite.dev.beeliked.com', 'pollen8.net'],
  uat: ['microsite.uat.beeliked.com', 'pollen8.co'],
  production: [
    'microsite.beeliked.com',
    'entry.promo',
    'scratch.promo',
    'reveal.promo',
    'advent.promo',
    'spinme.promo',
    'customers.promo',
    'moneyoff.promo',
    'shopper.promo',
    'follower.promo',
    'wheel.promo',
    'beeliked.promo',
    'guest.promo',
  ],
};

const environment = NEXT_PUBLIC_ENVIRONMENT as Env;

export const DOMAINS = domains[environment ?? 'local'];
